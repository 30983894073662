<!--
 * @Author: wyq
 * @Date: 2021-08-30 14:42:03
 * @LastEditTime: 2021-10-27 10:05:25
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \question_bank_console\src\views\knowledgePoint.vue
-->
<template>
  <page-container class="page-knowledge-graph" asideTitle="知识体系管理">
    <el-button slot="asideTitle" type="text" @click="showDialog('editRootDialog')">添加</el-button>
    <ul slot="aside" class="aside-bar">
      <li
        v-for="item in asideData"
        :key="item.id"
        :class="{'active':item.id==currentId}"
        @click="changeCurrentId(item.id)"
      >
        {{item.label}}
        <el-dropdown @command="handleCommand($event,item)">
          <i class="el-icon-more"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit">编辑</el-dropdown-item>
            <el-dropdown-item command="delete">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
    </ul>
    <content-container title="知识体系配置">
      <div class="item-box">
        <div class="label">
          <span>试题分类</span>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showDialog('editClassificationDialog',{id:currentId},'add')"
          >添加</el-button>
        </div>
        <el-tag
          v-for="item in tags"
          :key="item.id"
          type="info"
          closable
          @click="showDialog('editClassificationDialog',item,'edit')"
          @close="deleteCategory(item.id)"
        >{{item.name}}</el-tag>
      </div>
      <div class="item-box">
        <div class="label">
          <span>知识图谱</span>
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showDialog('editTreeDialog',{id:currentId},'add')"
          >添加父级</el-button>
        </div>
        <el-input placeholder="输入关键字进行过滤" v-model="filterText" size="mini"></el-input>
        <el-scrollbar style="height:500px">
          <el-tree
            ref="tree"
            :data="treeData"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :filter-node-method="filterNode"
            @node-click="treeNodeClick"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span>{{ node.label }}</span>
              <span v-show="currentTree.id==data.id">
                <el-button
                  type="warning"
                  size="mini"
                  plain
                  @click="showDialog('editTreeDialog',data,'edit')"
                >编辑</el-button>
                <el-button
                  type="primary"
                  size="mini"
                  plain
                  @click="showDialog('editTreeDialog',data,'add')"
                >添加子集</el-button>
                <el-button type="danger" size="mini" plain @click="deleteLabel(data.id)">删除</el-button>
              </span>
            </span>
          </el-tree>
        </el-scrollbar>
      </div>
      <div ref="katex" :key="currentTree.id">
        <div class="item-box">
          <div class="label">
            <span>当前标签</span>
            {{currentTree.label}}
          </div>
        </div>
        <div class="item-box">
          <div class="label">
            <span>说明</span>
          </div>
          {{currentTree.description}}
          <div class="desc-imgs">
            <template v-for="i in currentTree.images">
              <img :key="i.url" :src="i.url" />
            </template>
          </div>
        </div>
      </div>
    </content-container>
    <component
      :is="dialogName"
      :visible.sync="dialogVisible"
      :value="dialogValue"
      :type="dialogType"
      :grade="grade"
      :subject="subject"
      @updateLabel="updateLabel"
      @createLabel="createLabel"
      @getCategoryList="getCategoryList"
      :desc="false"
    ></component>
  </page-container>
</template>
<script>
import editRootDialog from '../components/knowledgeGraph/editRootDialog'
import editClassificationDialog from '../components/knowledgeGraph/editClassificationDialog'
import editTreeDialog from '../components/knowledgeGraph/editTreeDialog'
export default {
  components: {
    editRootDialog,
    editClassificationDialog,
    editTreeDialog,
  },
  data() {
    return {
      asideData: [],
      currentId: null,
      tags: [],
      filterText: '',
      treeData: [],
      currentTree: {},
      dialogName: '',
      dialogValue: null,
      dialogVisible: false,
      dialogType: '',
    }
  },
  computed: {
    grade() {
      return Number(this.$route.query.grade)
    },
    subject() {
      return Number(this.$route.query.subject)
    },
    libraryName() {
      return {
        grade: this.grade,
        subject: this.subject,
      }
    },
  },
  watch: {
    libraryName() {
      this.getList()
    },
    currentId(val) {
      if (val) {
        this.getTree()
        this.getCategoryList()
      }
    },
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    // 获取教材列表
    getList() {
      let params = { grade: this.grade, subject: this.subject }
      this.treeData = []
      this.$service.getKPList(params).then((res) => {
        this.asideData = res.book
        this.currentId = res.book ? res.book[0].id : null
      })
    },
    // 获取知识点分类
    getTree() {
      let params = {
        grade: this.grade,
        subject: this.subject,
        id: this.currentId,
      }
      this.$service.getKPTree(params).then((res) => {
        this.treeData = res.tree
        this.currentTree = {}
      })
    },
    // 获取教材试题分类列表
    getCategoryList() {
      let params = {
        bookId: this.currentId,
      }
      this.$service.getCategoryList(params).then((res) => {
        this.tags = res.list
      })
    },
    // 更新教材名称、知识点分类
    updateLabel(params, type = 'tree') {
      this.$service.updateKPLabel(params).then(() => {
        this.refreshTree(type)
        this.$message({
          type: 'success',
          message: '编辑成功!',
        })
      })
    },
    // 创建
    createLabel(params, type = 'tree') {
      this.$service.createKPLabel(params).then(() => {
        this.refreshTree(type)
        this.$message({
          type: 'success',
          message: '添加成功!',
        })
      })
    },
    changeCurrentId(id) {
      this.currentId = id
      this.getTree()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    treeNodeClick(obj) {
      this.currentTree = obj
      setTimeout(() => {
        this.$formula(this.$refs['katex'])
      }, 100)
    },
    showDialog(name, item = null, type = '') {
      console.log(type)
      this.dialogName = name
      this.dialogValue = item
      this.dialogVisible = true
      this.dialogType = type
    },
    // 点击树操作按钮
    handleCommand(command, item) {
      if (command == 'edit') {
        this.showDialog('editRootDialog', item)
      } else if (command == 'delete') {
        this.deleteLabel(item.id, 'aside')
      }
    },
    // 刷新树
    refreshTree(type = 'tree') {
      if (type == 'aside') {
        this.getList()
      } else if (type == 'tree') {
        this.getTree()
      }
    },
    // 删除图谱
    deleteLabel(id, type = 'tree') {
      this.$confirm('此操作将永久删除该图谱, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger',
        type: 'error',
      }).then(() => {
        let params = {
          grade: this.grade,
          subject: this.subject,
          id: id,
        }
        this.$service.deleteKPLabel(params).then(() => {
          this.refreshTree(type)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
      })
    },
    deleteCategory(id) {
      this.$confirm('此操作将永久删除该试题分类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        confirmButtonClass: 'el-button--danger',
        type: 'error',
      }).then(() => {
        let params = {
          grade: this.grade,
          subject: this.subject,
          id: id,
        }
        this.$service.deleteCategory(params).then(() => {
          this.getCategoryList()
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
      })
    },
  },
  mounted() {
    this.getList()
  },
}
</script>
<style lang="scss" scoped>
.page-knowledge-graph {
  .aside-bar {
    padding: 0;
    li {
      position: relative;
      display: block;
      height: 42px;
      padding: 0 36px;
      line-height: 42px;
      cursor: pointer;
      color: #999;
      font-size: 14px;
      &.active {
        background-color: #4e9c95;
        color: #fff;
      }
      .el-dropdown {
        position: absolute;
        right: 32px;
        color: inherit;
      }
    }
  }
  .item-box {
    font-size: 14px;
    color: #999999;
    white-space: pre-wrap;
    &:last-of-type {
      margin-bottom: 20px;
    }
    .label {
      margin: 20px 0;
      color: #999999;
      span {
        margin-right: 22px;
        color: #333333;
      }
    }
    .el-tag {
      border-radius: 7px;
      cursor: pointer;
      & + .el-tag {
        margin-left: 16px;
      }
    }
    .el-scrollbar {
      margin: 20px 0;
      background-color: #f4f6f8;
    }
    .el-tree {
      padding: 20px 0;
      background-color: #f4f6f8;
    }
    ::v-deep .el-tree-node__content {
      height: 36px;
      line-height: 36px;
    }
    ::v-deep .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      padding-right: 8px;
      .el-button--mini {
        margin-right: 10px;
      }
    }
  }
  .desc-imgs img {
    margin-top: 10px;
    margin-right: 10px;
    max-width: 100px;
    max-height: 200px;
  }
}
</style>